import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SponsorPage = () => (
  <Layout>
    <SEO
      title="Sponsorship inquiry sent"
      keywords={[
      ]}
    />

    <div className="p-4 pt-0 max-w-sm m-auto">
      <h2>
        Sponsorship inquiry sent
      </h2>
      <p>
        Thank you for your inquiry. We will respond via email with further details soon.
      </p>
    </div>
  </Layout>
);

export default SponsorPage;
